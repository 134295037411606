<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="detailCriticismFileDialog" persistent max-width="1080">
        <v-card class="pa-5">
          <v-card-title class="pl-3 ml-3">
            <v-row>
              <v-col class="text-h5 primary--text mb-5" cols="12">
                <div>Detalhes da Crítica</div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pl-3 ml-3">
            <v-row>
              <v-col>
                <v-data-table
                  class="elevation-1"
                  :headers="headerFiles"
                  :items="criticisms"
                  :loading="isLoadingCriticismsTable"
                  :items-per-page="5"
                  item-key="id"
                >
                <template v-slot:no-data>
                  <span>Nenhum dado encontrado.</span>
                </template>
                <template v-slot:[`item.critica.id`]="{ item }">
                  {{ item.critica.id ? item.critica.id : '-' }}
                </template>
                <template v-slot:[`item.critica.descricao`]="{ item }">
                  {{ item.critica.descricao ? item.critica.descricao : '-' }}
                </template>
                <template v-slot:[`item.indice`]="{ item }">
                  {{ item.indice ? item.indice : '-' }}
                </template>
                <template v-slot:[`item.campo`]="{ item }">
                  {{ item.campo ? item.campo : '-' }}
                </template>
                <template v-slot:[`item.descricao`]="{ item }">
                  {{ item.descricao ? item.descricao : '-' }}
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row class="mt-6">
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-btn
                  class="mr-3 pa-6"
                  color="#3B495B"
                  outlined
                  block
                  @click="close()"
                >
                  OK
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import ProcessControlCriticismFileService from '@/services-http/analytics/ProcessControlCriticismFileService';

export default ({
  name: 'DetailCriticismFileDialog',
  data: () => ({
    detailCriticismFileDialog: false,
    isLoadingCriticismsTable: false,
    headerFiles: [
      {
        text: 'Código',
        value: 'critica.id',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Descrição',
        value: 'critica.descricao',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Linha',
        value: 'indice',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Campo',
        value: 'campo',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Detalhe',
        value: 'descricao',
        align: 'center',
        sortable: true,
      },
    ],
    criticisms: [],
  }),

  props: {
    datailsCriticismData: Object,
  },

  methods: {
    open() {
      this.processControlCriticismFileService.FindAllByFilters(this.datailsCriticismData.id).then((response) => {
        if (response && response.data.content) {
          this.criticisms = response.data.content;
        }
      });
      this.detailCriticismFileDialog = true;
    },
    close() {
      this.detailCriticismFileDialog = false;
    },
  },

  created() {
    this.processControlCriticismFileService = new ProcessControlCriticismFileService();
  },
});
</script>
