<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="checklistCompetenceDialog" persistent max-width="1080">
        <v-card class="pa-5">
          <v-card-title class="pl-3 ml-3">
            <v-row>
              <v-col class="text-h5 primary--text mb-5" cols="12">
                <div>Checklist</div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pl-3 ml-3">
            <v-row>
              <v-col>
                <v-data-table
                  class="elevation-1"
                  :headers="headerChecklists"
                  :items="checklists"
                  :loading="isLoadingChecklistTable"
                  :items-per-page="10"
                  item-key="id"
                >
                <template v-slot:no-data>
                  <span>Nenhum dado encontrado.</span>
                </template>
                <template v-slot:[`item.checklistConfig.checklist.descricao`]="{ item }">
                  {{ item.checklistConfig.checklist.descricao }}
                </template>
                <template v-slot:[`item.validado`]="{ item }">
                  {{ item.validado === true ? 'Sim' : 'Não' }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-row class="justify-center">
                    <v-checkbox
                      v-if="item.validado === true"
                      color="textPrimary"
                      v-model="item.validado"
                      disabled
                    />
                    <v-checkbox
                      v-else
                      color="textPrimary"
                      v-model="item.validado"
                      @click="validateChecklist(item)"
                    />
                  </v-row>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row class="mt-6">
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-btn
                  class="mr-3 pa-6"
                  color="#3B495B"
                  outlined
                  block
                  @click="close()"
                >
                  OK
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col>
        <v-snackbar
          v-model="snackbar.show"
          timeout="10000"
          top
          elevation="24"
          :color="snackbar.type"
        >
          <div class="text-center">{{ snackbar.text }}</div>
          <template v-slot:action="{ attrs }">
            <v-icon
              text
              dark
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              close
            </v-icon>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogConfirmation" max-width="700px">
      <v-card class="pa-5">
        <v-card-title class="text-h5 justify-center primary--text">
          Você tem certeza que deseja prosseguir?
        </v-card-title>
        <v-card-actions>
        <v-row class="mt-6">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              outlined
              block
              @click="closeDialogConfirmation()"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              block
              style="color: white"
              @click="confirmValidateChecklist()"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ProcessControlChecklistConfigService from '@/services-http/analytics/ProcessControlChecklistConfigService';

export default ({
  name: 'ChecklistCompetenceDialog',
  data: () => ({
    checklistCompetenceDialog: false,
    isLoadingChecklistTable: false,
    itemValidation: false,
    dialogConfirmation: false,
    checklistId: 0,
    checklists: [],
    headerChecklists: [
      {
        text: 'Descrição',
        value: 'checklistConfig.checklist.descricao',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Validado',
        value: 'validado',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Validar',
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ],
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  props: {
    checklistCompetencesData: Array,
  },

  watch: {
    checklistCompetencesData(newValue) {
      this.checklists = newValue;
    },
  },

  methods: {
    open() {
      this.checklistCompetenceDialog = true;
    },
    close() {
      this.checklistCompetenceDialog = false;
    },
    validateChecklist(item) {
      this.itemValidation = item;
      this.dialogConfirmation = true;
      this.checklistId = item.id;
    },
    confirmValidateChecklist() {
      this.processControlChecklistConfigService.Validate(this.checklistId).then((reponse) => {
        this.customizeSnackbarMessage('success', 'Validado com sucesso');
        this.dialogConfirmation = false;
      }).catch((error) => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao prosseguir');
        this.dialogConfirmation = false;
      });
    },
    closeDialogConfirmation() {
      this.checklists.forEach((element, index) => {
        if (element.id === this.itemValidation.id) {
          if (element.validado === true) {
            this.checklists[index].validado = null;
          }
        }
      });
      this.dialogConfirmation = false;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },

  created() {
    this.processControlChecklistConfigService = new ProcessControlChecklistConfigService();
  },
});
</script>
