<template>
  <v-container fluid class="ma-4">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Acompanhamento das cargas de fatura e sinistros</h1>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-10 pr-4">
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Operadora</label>
          <v-autocomplete
            :loading="isLoadCarriers"
            class="mt-2"
            v-model="filter.insuranceCarrier"
            :items="insuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="xipp_commercial_name"
            outlined
            hide-details
            placeholder="Selecione a Operadora"
            color="textPrimary"
            item-color="textPrimary"
            append-icon="fas fa-chevron-down"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Número da apólice</label>
          <v-text-field
            v-model="filter.policyNumber"
            class="mt-2"
            placeholder="Informe a apólice"
            outlined
            type="number"
            min="0"
            color="textPrimary"
            required
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Data da Competência</label>
            <v-text-field
              v-model="filter.competenceDate"
              v-mask="'##/####'"
              required
              clearable
              placeholder="MM/AAAA"
              outlined
              class="mt-2"
            >
            </v-text-field>
        </v-col>
        <v-col class="mt-8" cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn
            v-if="hasAuthorityCompetenceViewTab || hasAuthorityFileViewTab"
            x-large
            dark
            color="textPrimary"
            width="250px"
            @click="getCompetenceByFilters()"
          >
            Pesquisar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" class="my-2" justify="space-between" >
        <v-tabs v-model="configurationTabs" color="textPrimary" background-color="transparent" >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-if="hasAuthorityCompetenceViewTab" class="tab-title" href="#tab-competence" @click="getCompetenceByFilters()">Competência</v-tab>
          <v-tab v-if="hasAuthorityFileViewTab" :disabled="isDisableFile" class="tab-title" href="#tab-file">Arquivo</v-tab>
        </v-tabs>
        <v-row>
          <v-col cols="12">
            <v-tabs-items  v-model="configurationTabs">
              <v-tab-item v-if="hasAuthorityCompetenceViewTab" :value="'tab-competence'">
                <v-data-table
                  class="elevation-1"
                  :headers="headerCompetences"
                  :items="competences"
                  :loading="isLoadingCompetenceTable"
                  :page.sync="page"
                  :items-per-page="10"
                  hide-default-footer
                  item-key="id"
                >
                <template v-slot:no-data>
                  <span>Nenhum dado encontrado.</span>
                </template>
                <template v-slot:[`item.competencia`]="{ item }">
                  {{ item.competencia ? formatter.formatDate(item.competencia) : '-' }}
                </template>
                <template v-slot:[`item.dataCriacao`]="{ item }">
                  {{ item.dataCriacao ? formatter.formatDate(item.dataCriacao) : '-' }}
                </template>
                <template v-slot:[`header.actions`]="{ header }">
                  <span>{{ hasAuthorityGoToFiles || hasAuthorityViewChecklist ? header.text : null }}</span>
                </template>
                <template v-if="hasAuthorityGoToFiles || hasAuthorityViewChecklist" v-slot:[`item.actions`]="{ item }">
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                        <v-icon color="textPrimary" size="20">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-if="hasAuthorityViewChecklist" @click="openChecklistCompetenceDialog(item)">
                        <v-list-item-icon class="mr-2">
                          <v-icon size="20" color="textPrimary">fas fa-tasks</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="mt-1">
                          <v-list-item-title>Ver checklist</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="hasAuthorityGoToFiles && hasAuthorityFileViewTab" @click="onClickGoToFiles(item, 'init')">
                        <v-list-item-icon class="mr-2">
                          <v-icon size="20" color="textPrimary">fas fa-file-export</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="mt-1">
                          <v-list-item-title>Ir para arquivos</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                </v-data-table>
                <div class="float-right pt-2 pr-5">
                  <v-pagination
                    v-model="page"
                    color="textPrimary"
                    :length="totalPages"
                    :total-visible="9"
                    @input="getCompetenceByFilters();"
                  />
                </div>
              </v-tab-item>

              <v-tab-item v-if="hasAuthorityFileViewTab" :value="'tab-file'">
                <v-data-table
                  class="elevation-1"
                  :headers="headerFiles"
                  :items="files"
                  :loading="isLoadingFiles"
                  :items-per-page="10"
                  item-key="id"
                >
                  <template v-slot:no-data>
                    <span>Nenhum dado encontrado.</span>
                  </template>
                  <template v-slot:[`item.timeUpload`]="{ item }">
                    {{ item.timeUpload ? formatter.formatDate(item.timeUpload) : '-' }}
                  </template>
                  <template v-slot:[`item.fatura`]="{ item }">
                    {{ item.fatura ? 'Fatura' : 'Sinistro' }}
                  </template>
                  <template v-slot:[`item.dataUltimaAlteracao`]="{ item }">
                    {{ item.dataUltimaAlteracao ? formatter.formatDate(item.dataUltimaAlteracao) : '-' }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                          <v-icon color="textPrimary" size="20">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item v-if="hasAuthorityViewCriticism" @click="openDetailCriticismFileDialog(item)">
                          <v-list-item-icon class="mr-2">
                            <v-icon size="20" color="textPrimary">fas fa-tasks</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="mt-1">
                            <v-list-item-title>Ver críticas</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.status === 'Cancelado'">
                          <v-list-item-icon class="mr-2">
                            <v-icon size="20" color="error">fas fa-ban</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="mt-1">
                            <v-list-item-title>Arquivo cancelado</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <div v-else>
                          <v-list-item v-if="hasAuthorityCancelFiles" @click="cancelFile(item)">
                            <v-list-item-icon class="mr-2">
                              <v-icon size="20" color="textPrimary">fas fa-file-export</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="mt-1">
                              <v-list-item-title>Cancelar o arquivo</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>
    <v-row>

    <v-dialog v-model="dialogConfirmation" max-width="700px">
      <v-card class="pa-5">
        <v-card-title class="text-h5 justify-center primary--text">
          Você tem certeza que deseja prosseguir?
        </v-card-title>
        <v-card-actions>
        <v-row class="mt-6">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              outlined
              block
              @click="closeDialogCancelFile()"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              block
              style="color: white"
              @click="confirmCancelFile()"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-col>
        <v-snackbar
          v-model="snackbar.show"
          timeout="10000"
          top
          elevation="24"
          :color="snackbar.type"
        >
          <div class="text-center">{{ snackbar.text }}</div>
          <template v-slot:action="{ attrs }">
            <v-icon
              text
              dark
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              close
            </v-icon>
          </template>
        </v-snackbar>
      </v-col>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="120">
          <div>Carregando...</div>
        </v-progress-circular>
      </v-overlay>
    </v-row>

    <ChecklistCompetenceDialog :checklistCompetencesData="this.checklists" ref="ChecklistCompetenceDialog" />
    <DetailCriticismFileDialog :datailsCriticismData="this.datailsCriticismData" ref="DetailCriticismFileDialog" />
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import ChecklistCompetenceDialog from '@/components/SinisterAndInvoice/ChecklistCompetenceDialog.vue';
import DetailCriticismFileDialog from '@/components/SinisterAndInvoice/DetailCriticismFileDialog.vue';
import CarrierService from '@/services-http/contract/CarrierService';
import ProcessControlService from '@/services-http/analytics/ProcessControlService';
import ProcessControlChecklistConfigService from '@/services-http/analytics/ProcessControlChecklistConfigService';
import ProcessControlFileService from '@/services-http/analytics/ProcessControlFileService';
import UserUtils from '@/shared/utils/user-utils';

export default ({
  components: { ChecklistCompetenceDialog, DetailCriticismFileDialog },

  data: () => ({
    hasAuthorityCompetenceViewTab: false,
    hasAuthorityFileViewTab: false,
    hasAuthorityViewChecklist: false,
    hasAuthorityViewCriticism: false,
    hasAuthorityGoToFiles: false,
    hasAuthorityCancelFiles: false,

    page: 1,
    totalPages: 0,
    dialogConfirmation: false,
    valid: true,
    isLoadCarriers: false,
    isDisableFile: true,
    overlay: false,
    isLoadingCompetenceTable: false,
    configurationTabs: 'tab-competence',
    isLoadingFiles: false,
    insuranceCarriers: ['GNDI', 'Bradesco', 'SulAmérica', 'Amil'],
    competences: [],
    checklists: [],
    datailsCriticismData: {},
    processControlId: 0,
    processControlFile: {},
    headerCompetences: [
      {
        text: 'Apólice',
        value: 'apolice',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Operadora',
        value: 'operadora',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Data de Competência',
        value: 'competencia',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Status',
        value: 'status',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Data início de processamento',
        value: 'dataCriacao',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ],
    headerFiles: [
      {
        text: 'Nome',
        value: 'nome',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Data / Hora do upload',
        value: 'dataUltimaAlteracao',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Tipo do arquivo',
        value: 'fatura',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Status',
        value: 'status',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ],
    files: [],
    filter: {
      insuranceCarrier: '',
      competenceDate: '',
      policyNumber: '',
    },
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  methods: {
    async loadAuthoritySinister() {
      this.hasAuthorityCompetenceViewTab = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cargas_sinistros_faturas_visualizar_aba_competencia' });
      this.hasAuthorityFileViewTab = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cargas_sinistros_faturas_visualizar_aba_arquivos' });
      this.hasAuthorityViewChecklist = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cargas_sinistros_faturas_visualizar_checklist' });
      this.hasAuthorityViewCriticism = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cargas_sinistros_faturas_visualizar_criticas' });
      this.hasAuthorityGoToFiles = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cargas_sinistros_faturas_ir_para_arquivos' });
      this.hasAuthorityCancelFiles = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cargas_sinistros_faturas_cancelar_arquivos' });
    },
    getCompetenceByFilters() {
      this.isLoadingCompetenceTable = true;
      if (this.filter.insuranceCarrier || this.filter.policyNumber || this.filter.competenceDate) {
        const queryParameters = {
          apolice: this.filter.policyNumber ? this.filter.policyNumber : null,
          competencia: this.filter.competenceDate ? `01/${this.filter.competenceDate}` : null,
          operadora: this.filter.insuranceCarrier ? this.filter.insuranceCarrier : null,
          page: this.page - 1,
          size: 10,
        };
        this.processControlService.FindAllByFilters(queryParameters).then((response) => {
          if (response && response.data.content) {
            this.competences = response.data.content;
            this.totalPages = response.data.totalPages;
          }
          this.isLoadingCompetenceTable = false;
        }).catch((error) => {
          this.isLoadingCompetenceTable = false;
        });
      } else {
        this.customizeSnackbarMessage('error', 'Não há parâmetros de pesquisa');
        this.isLoadingCompetenceTable = false;
      }
    },
    async openChecklistCompetenceDialog(item) {
      if (item && item.id) {
        const { id } = item;
        await this.processControlChecklistConfigService.FindAllByFilters({ controleProcessamentoId: id }).then((response) => {
          if (response && response.data.content) {
            this.checklists = response.data.content;
          }
          this.$refs.ChecklistCompetenceDialog.open();
        }).catch((error) => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao verificar os checklists');
        });
      }
    },
    onClickGoToFiles(item, type) {
      this.isLoadingFiles = true;
      if (item && type === 'init') {
        this.isDisableFile = false;
        this.processControlId = item.id;
        this.configurationTabs = 'tab-file';
        this.processControlFileService.FindAllByFilters(this.processControlId).then((response) => {
          this.files = response.data.content;
          this.isLoadingFiles = false;
        }).catch((error) => {
          this.isLoadingFiles = false;
        });
      } else if (item && type === 'finally') {
        this.isDisableFile = false;
        this.processControlFileService.FindAllByFilters(this.processControlId).then((response) => {
          this.files = response.data.content;
          this.isLoadingFiles = false;
        }).catch((error) => {
          this.isLoadingFiles = false;
        });
      }
    },
    openDetailCriticismFileDialog(item) {
      this.datailsCriticismData = item;
      this.$refs.DetailCriticismFileDialog.open();
    },
    cancelFile(item) {
      this.processControlFile = item;
      this.dialogConfirmation = true;
    },
    confirmCancelFile() {
      this.processControlFileService.Cancellation(this.processControlFile.id).then((response) => {
        this.customizeSnackbarMessage('success', 'Arquivo cancelado com sucesso');
        this.closeDialogCancelFile();
        this.onClickGoToFiles(this.processControlFile.id, 'finally');
      }).catch((error) => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao cancelar');
        this.closeDialogCancelFile();
      });
    },
    closeDialogCancelFile() {
      this.dialogConfirmation = false;
    },
    onClickCancel() {
      this.$router.push({ name: 'HomeAllMenus' });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },

  async created() {
    this.userUtils = new UserUtils();
    await this.loadAuthoritySinister();
    this.formatter = new Formatters();
    this.carrierService = new CarrierService();
    this.processControlService = new ProcessControlService();
    this.processControlFileService = new ProcessControlFileService();
    this.processControlChecklistConfigService = new ProcessControlChecklistConfigService();
  },
});
</script>
