/* eslint-disable */
import HttpService from "../HttpService";

export default class ProcessControlChecklistConfigService {
  constructor() {
    this._httpService = new HttpService('/analytics/process_control_checklist_config')
  }

  async FindAllByFilters(params) {
    return this._httpService.get('/find_all_by_filters', params);
  }

  async Validate(processControleChecklistConfigId) {
    return this._httpService.post(`/${processControleChecklistConfigId}/validation`)
  }
}