/* eslint-disable */
import HttpService from '../HttpService';

export default class ProcessControlCriticismFileService {
  constructor() {
    this._httpService = new HttpService('/analytics/criticize_process_control_file');
  }

  async FindAllByFilters(params) {
    return this._httpService.get('/find_all_by_filters', params);
  }
}
