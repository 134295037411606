/* eslint-disable */
import HttpService from '../HttpService';

export default class ProcessControllService {
  constructor() {
    this._httpService = new HttpService('/analytics/process_control');
  }

  async FindAllByFilters(params) {
    return this._httpService.get('/find_all_by_filters', params);
  }
}
